
import { ICountry } from '../common/country';
import { ICurrency } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { IPrefix } from '../common/prefix';
import { IFooter } from './footer';

export enum Webapps {
  bartOld = 1,
  lisa = 2,
  maggie,
  patty,
  winchester,
  selma,
  bart = 8
}

export namespace Webapps {
  export function values(): Array<IdName<any>> {
    return idNames(Webapps);
  }
}

export interface IWebAppTranslationUpdateRequest {
  webAppTranslationId: number;
  bookmakerId: number;
  languageId: number;
  value: string;
  webAppId: number;
}

export interface IWebAppTranslationRequest {
  webAppId: number;
  bookmakerId: number;
  sourceLanguageId: number;
  targetLanguageId: number;
}

export interface IWebAppTranslation {
  webAppId: number;
  bookmakerId: number;
  webAppTranslationId: number;
  webAppTranslation: string;
  sourceLanguageId: number;
  targetLanguageId: number;
  source: string;
  target: string;
}


export interface IVisitor {
  countryCode: string;
  geoBlocked: boolean;
}

export interface IWebapp {
  version: string;
  currencies: Array<ICurrency>;
  countries: Array<ICountry>;
  prefixes: Array<IPrefix>;
  footer: IFooter;
  visitor: IVisitor;
  features: IWebAppFeatures;
}

export interface IWebAppFeatures {
  themeSwitcher: boolean
  password: {
    recovery: {
      phone: boolean;
      email: boolean;
    }
  }
}
